import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import { BrowserRouter } from "react-router-dom";
import { I18nextProvider } from "react-i18next";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { ThemeProvider as StyledComponentThemeProvider } from "styled-components";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import i18n from "./i18n";

library.add(fas);

const queryClient = new QueryClient();

const defaultTheme = {
  typography: {
    fontFamily: [
      "SF Pro Text",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
  primary: "#46795b",
  secondary: "#888",
  disable: "#DBDBDB",
  danger: "#d32f2f",
  border: "#CDDFED",
  light: "#6F8BA4",
  text: "#3B566E",
  gray: "#DBDBDB",
  darkBlue: "#183A33",
  buttonSecondary: "#FEA47F",
  lightGreen: "rgb(246, 248, 244)",
};

const theme = createTheme({
  ...defaultTheme,
});

ReactDOM.render(
  <React.StrictMode>
    <StyledComponentThemeProvider theme={defaultTheme}>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={theme}>
          <I18nextProvider i18n={i18n}>
            <BrowserRouter>
              <App />
            </BrowserRouter>
          </I18nextProvider>
        </ThemeProvider>
      </QueryClientProvider>
    </StyledComponentThemeProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
