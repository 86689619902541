import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { APIRequestGenerator } from "../base/BaseActions";
import APIHelper from "../base/APIHelper";
import BaseModel from "../base/BaseModel";

const useAppModel = (savedModelInstance = {}) => {
  const { t, i18n } = useTranslation();
  const [appIcon, setAppIcon] = useState(savedModelInstance?.appIcon);
  const [headerHeight, setHeaderHeight] = useState(0);
  const [country, setCountry] = useState([]);
  const [lang, setLang] = useState(savedModelInstance?.lang || "en");

  const onSaveInstanceState = useCallback(
    () => ({
      appIcon,
      lang: i18n.language,
    }),
    [appIcon, i18n.language]
  );

  async function handeUploadFile(token, file) {
    const { url, headers } = APIRequestGenerator(
      `${process.env.REACT_APP_HOST}/media/upload`,
      null,
      token
    );
    try {
      const response = await APIHelper("POST", url.toString(), file, false, {
        accept: "application/json, text/plain, /",
      });

      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async function getCountryList() {
    const { url, headers } = APIRequestGenerator(
      `${process.env.REACT_APP_HOST}/country?_limit=200&_sort=-sorting name`,
      null,
      null
    );
    try {
      const response = await APIHelper(
        "GET",
        url.toString(),
        null,
        true,
        headers
      );
      setCountry(response.records);
      return Promise.resolve(response);
    } catch (error) {
      setCountry([]);
      return Promise.reject(error);
    }
  }

  async function getStateList(Country) {
    const { url, headers } = APIRequestGenerator(
      `${process.env.REACT_APP_HOST}/state?country=${Country}&_sort=name&_limit=99`,
      null,
      null
    );
    try {
      const response = await APIHelper(
        "GET",
        url.toString(),
        null,
        true,
        headers
      );
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  function handleChangeLang() {
    i18n.changeLanguage(i18n.language === "en" ? "zh" : "en");
    setLang(i18n.language === "en" ? "zh" : "en");
  }

  useEffect(() => {
    i18n.changeLanguage(lang);
  }, [lang]);

  return {
    appIcon,
    onSaveInstanceState,
    setHeaderHeight,
    headerHeight,
    handeUploadFile,
    getCountryList,
    country,
    getStateList,
    handleChangeLang,
  };
};

const AppModel = BaseModel(useAppModel, "AppModel");

export { useAppModel };
export default AppModel;
