import React, { useContext, useEffect } from "react";
import { useTheme } from "@mui/material/styles";
import { useNavigate, Link, useLocation } from "react-router-dom";
import { RiArrowDownSLine } from "react-icons/ri";
import { useTranslation } from "react-i18next";
import VerifiedIcon from "@mui/icons-material/Verified";
import {
  Container,
  MiddleRow,
  LeftRow,
  LogOut,
  LogoImg,
  Name,
  ChangeLngAndLoginRow,
  LinkRow,
  LangText,
} from "./Header.styles";
import Logo from "../../../assets/images/Logo2.png";
import AppModel from "../../../models/appModel";
import UserModel from "../../../models/userModel";
import { HOME, PROFILE, FAQ, AUTH, MYDOCUMENT } from "../path";
import useThrottledResizeObserver from "../../../hooks/useThrottledResizeObserver";
import DocumentModel from "../../../models/documentModel";

export default function Header() {
  const theme = useTheme();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const { setHeaderHeight, handleChangeLang } = useContext(AppModel.Context);
  const { logout, userData = {}, token } = useContext(UserModel.Context);
  const { ref: headerRef, height: headerHeight } =
    useThrottledResizeObserver(0);
  const { saveTemplate, saveDocument, saveSession } = useContext(
    DocumentModel.Context
  );

  useEffect(() => {
    setHeaderHeight(headerHeight + 20);
  }, [headerHeight]);

  const { pathname } = useLocation();

  const { firstName, lastName, isUnlimited, username } = userData || {};

  return (
    <Container
      ref={headerRef}
      theme={theme}
      style={{
        backgroundColor: "#f6f8f4",
      }}
    >
      <LeftRow>
        <a
          href="https://awesumcare.com"
          style={{ display: "flex", alignItems: "center" }}
        >
          <LogoImg src={Logo} />
        </a>
      </LeftRow>
      <MiddleRow
        theme={theme}
        // style={{ visibility: isAnswering ? "hidden" : "visible" }}
      >
        <LinkRow>
          <Link to={HOME}>{t("Home.title")} </Link>
          <Link to={MYDOCUMENT}>{t("Profile.myDocument")}</Link>
          <Link to={PROFILE}>{t("Profile.title")}</Link>
          <Link to={FAQ}>{t("FAQ.title")}</Link>
          {token && (
            <Name theme={theme}>
              {username}
              {isUnlimited && (
                <VerifiedIcon style={{ fill: theme.primary, marginLeft: 10 }} />
              )}
            </Name>
            // <Name theme={theme}>
            //   {firstName}
            //   {"\n"}
            //   {lastName}
            //   {isUnlimited && (
            //     <VerifiedIcon style={{ fill: theme.primary, marginLeft: 10 }} />
            //   )}
            // </Name>
          )}
        </LinkRow>

        <ChangeLngAndLoginRow>
          {token && (
            <Name theme={theme}>
              {username}
              {isUnlimited && (
                <VerifiedIcon style={{ fill: theme.primary, marginLeft: 10 }} />
              )}
            </Name>
            // <Name theme={theme}>
            //   {firstName}
            //   {"\n"}
            //   {lastName}
            //   {isUnlimited && (
            //     <VerifiedIcon style={{ fill: theme.primary, marginLeft: 10 }} />
            //   )}
            // </Name>
          )}
          <div>
            <button
              type="button"
              style={{
                background: "#a72f2f",
                borderRadius: 20,
                display: "flex",
                alignItems: "center",

                height: 30,
                padding: "0px 15px",
              }}
              onClick={() => handleChangeLang()}
            >
              <LangText>{i18n.language === "en" ? "中" : "EN"}</LangText>
              <RiArrowDownSLine />
            </button>
          </div>
          <div
            style={{
              height: "70%",
              width: 1,
              backgroundColor: "#c1d0d9",
              marginRight: 10,
              marginLeft: 10,
            }}
          />

          {token ? (
            <LogOut
              onClick={() => {
                saveTemplate(null);
                saveDocument(null);
                saveSession(null);
                logout(navigate);
              }}
            >
              <span>{t("logout")}</span>
            </LogOut>
          ) : (
            <LogOut
              onClick={() => {
                saveTemplate(null);
                saveDocument(null);
                saveSession(null);
                navigate(AUTH);
              }}
            >
              <span>{t("login")}</span>
            </LogOut>
          )}
        </ChangeLngAndLoginRow>
      </MiddleRow>
    </Container>
  );
}
