export default {
  mainTitle: "AWEsum Care",
  answer: "Answer",
  username: "Username",
  logout: "Logout",
  login: "Login",
  phone: "Phone number",
  password: "Password",
  confirmPassword: "Confirm Password",
  name: "Name",
  gender: "Gender",
  f: "Female",
  m: "Male",
  surname: "Surname",
  dob: "Date Of Birth",
  dateOfBirth: "Date Of Birth",
  email: "Email",
  download: "Download",
  salutation: "Salutation",
  firstName: "First Name",
  firstname: "First Name",
  lastName: "Last Name",
  firstNameZh: "First Name(Chinese)",
  lastNameZh: "Last Name(Chinese)",
  hkid: "HKID/ Passport no.",
  country: "Country / Jurisdiction",
  address: "Address",
  line1: "Line 1",
  line2: "Line 2",
  line3: "Line 3",
  city: "City",
  state: "State / Province / Territory",
  zip: "Zip Code",
  select: "Please Select",
  Will: "Will and Testament",
  EPA: "Enduring Power of Attorney",
  AD: "Advance Medical Directive",
  notPaid: "Not Paid",
  noRecord: "No Record",
  logoutsuccess: "You have successfully logged out",
  loading: "LOADING...",
  downloaded: "Downloaded",
  other: "Other",
  cancel: "Cancel",
  pleaseLogin: "Please Login",
  promotionCode: "Promotion Code",

  Category: {
    title: "Category",
  },
  SignIn: {
    title: "Sign in",
    rememberMe: "Remember Me",
    forgotPassword: "Forgot Password?",
    or: "OR",
    facebook: "Facebook",
    google: "Google",
    signUp: " Sign up",
    need: "Need an account?",
    pleaseLogin: "Please Login to Create Document",
    keep: "Keep me logged in",
    alreadyAc: "Already have an account: ",
    login: " Log-in",
    other: "Or Sign In/Sign Up Using",
  },
  SignUp: {
    title: "New Registration",
    register: "REGISTER",
    already: "Already have an account?",
    loginhere: "Login Here",
    info: "Your basic information",
    ensure:
      "Please ensure that you provide your complete and correct details (in accordance with your identity documents) as they will be auto-filled into future documents. The information provided will be kept confidential. For details, please refer to our",
    privacyPolicy: "Privacy Policy",
    next: "Next",
    submit: "SUBMIT",
    mr: "Mr.",
    ms: "Ms.",
    mrs: "Mrs",
    dr: "Doctor",
    miss: "Miss",
    HongKong: "Hong Kong",
    passwordRequired:
      "The password requires at least 8 characters/numbers including one lowercase letter and one uppercase letter.",
    regSucess: "Registration Successful",
    confirmPasswordError: "Please enter the same as password",
    tncText:
      "By registration, I confirm that I am over 18 years old, and agree with the ",
    tnc: "Terms & Conditions",
    policy: "Privacy Policy",
    refund: "Refund Policy",
    picsText:
      "I do not agree to the use of my personal data for direct marketing purposes in accordance to the",
    pics: "(PICS)",
    picsLong: "Personal Information Collection Statement",
    picsText1: "",
    and: "and the",
  },
  Home: {
    title: "Home",
    recent: "Recent Documents",
    Categories: "Categories",
    willforsingle: "Will for single",
    documents: "Create New Document",
    clear: "Clear History",
    template: "Template",
    detail: "Detail",
    noRecord: "No Records",
    adDescription:
      "An advanced directive indicates the form of medical treatment that you would like to receive in the future when you are no longer able to decide. Download your advanced directive here!",
    epaDescripton:
      "An enduring power of attorney (EPA) is a document that allows you to appoint a person or organization to manage your affairs if you become unable to do so. Download your EPA here!",
    willDescription:
      "A will sets out how a person's assets are to be distributed after his or her death. Check out our will templates here!",
    languageRemark:
      "Please use the selected language to fill in details, please use English if English is selected ",
    findLayer: "Lawyer / Doctor Booking",
    consultant: "General Consultation\n(Non-legal)",
    otherService: "Other Services",
    consultantText:
      "Book experienced consultants for personalised advice on succession and advance care planning, to protect you and your family.",
    findLayerText:
      "Book experienced lawyers and doctors to draft / witness your Will, Enduring Power of Attorney, and Advance Medical Directives. ",
    consult: "consult",
    appoint: "appoint",
  },
  AdvanceCarePlanning: {
    title: "Advance Care Planning",
    categoryTitle: "Which category would you wish to make?",
    title1: "Which document would you wish to make?",
    text1:
      "Please note that 安心三寶 ACP is an online platform providing templates and information. 安心三寶 ACP is not a law firm and does not provide legal or professional advice on estate planning.",
    Will: "Will and Testament",
    EPA: "Enduring EPA",
    AD: "Advance Medical Directive",
    willText:
      "A Will is a document which sets out how you would want your assets to be distributed after your death.",
    epaText:
      "EPA is a legal document that allows you to appoint attorney(s) to take care of your financial matters should you become mentally incapacitated.",
    adText:
      "Advance Medical Directive is a statement to indicate the treatment you would like to refuse when you are no longer mentally competent.",
  },
  Profile: {
    profile: "Profile",
    title: "Profile",
    edit: "Edit Profile",
    myDocument: "My Documents",
    submit: "SUBMIT",
    newPassword: "New Password",
    confirmNewPassword: "Confirm New Password",
    success: "Information has been updated",
    notPaid: "PAY NOW",
    notFinished: "Incomplete",
    editContentList: "Contact List",
    delete: "Delete",
    deleteMsg:
      "Are you sure you want to delete the document? Please note that it will be deleted from our system and you will not be able to download it again.",
    changePassword: "Password",
    editDraft: "Edit Draft",
  },
  FAQ: {
    title: "FAQ",
    type: "Type",
    Will: "Will and Testament",
    EPA: "Enduring Power of Attorney",
    AD: "Advance Medical Directive",
    faqFor: "Glossary & FAQs for",
    willTitle: "Glossary & FAQs for",
    epaTitle: "Glossary & FAQs for",
    epaFullForm: "Enduring Power of Attorney",
    adTitle: "Glossary & FAQs for",
    adFullForm: "Advance Medical Directive",
    next: "Next",
    prev: "Previous",
    glossary: "Glossary",
    create: "CREATE",
    minError: "You need to choose more than ",
    minError1: "options",
    requiredError: "Please answer the question",
    otherError: "Please fill all the input field",
    maxError: "You already reached the maximum number of accepted choices",
    maxError1: "",
    loopNext: "Add Contact",
    saveAnswer: "Save Answer",
    endLoop: "finish this question",
    necessary: "This is a mandatory question.",
    save: "Save Draft",
    saveContentList: "save changes",
    addContentList: "add to contact list",
    maxLoopCount:
      "You already reached the maximum number of accepted answer max:{{loopCount}}",
    provide: "Please fill in first name and surname",
    submit: "Submit",
    start: "Start",
  },

  Setting: {
    title: "Setting",
    language: "Language",
  },
  Validation: {
    required: "▲ Required",
    formatError: "▲ Invalid ",
    inValidData: "Invalid Date",
  },
  BlurScreen: {
    thanks: "Thanks for your information",
    text1: "We have selected a suitable template for you.",
    text2: "You have answered all the question",
    goto: "GO TO DOCUMENT",
    create: "CREATE",
    backHome: "Go to Home Page",
    Will: "Will ",
    EPA: "EPA",
    AD: "AMD",
    noDocument: "Sorry,there are no suitable template for you",
    continue: "CONTINUE",
    reviewFAQ:
      "Please review the complete Glossary and FAQ in order to proceed to the creation of document",
    review: "Review Glossary and FAQ",
    documentRemark1:
      "<p style=font-size:16px;margin-top:10px;margin-bottom:0px;font-weight:bold;text-align:left;>1. Please ensure you fill in details in English if you choose English document, and vice versa</p>",
    documentRemark2:
      "<p style=font-size:16px;margin-top:10px;margin-bottom:0px;font-weight:bold;text-align:left;>2. Please don’t delete important clauses when making changes, otherwise it may not be valid</p>",
    documentRemark3:
      "<div style=font-size:16px;margin-top:10px;margin-bottom:0px;font-weight:bold;text-align:left;>3. Please get the document witnessed, if you need doctor or lawyer referral, please go to <a target='_blank' href='https://awesumcare.com/%E9%A0%90%E7%B4%84%E8%A6%8B%E8%AD%89%E6%9C%8D%E5%8B%99/'>awesumcare.com/預約見證服務/</a></div>",
    documentRemark4:
      "<div style=font-size:16px;margin-top:10px;margin-bottom:0px;font-weight:bold;text-align:left;>4. Once you click create, you will no longer be able to edit your draft. If you need to make any amendments, please download the word version and change it accordingly after payment.</div>",
    saveDraft: "Save draft",
  },
  Support: {
    buy: "Buy Prokens",
    monthly: "Monthly Subscription",
    noSupport: "No Support",
    your: "Your",
    isReady: " is ready!",
    Will: " Will and Testament",
    EPA: " Enduring Power of Attorney",
    AD: " Advance Medical Directive",
    pdf: "Download PDF",
    word: "Download Word Document",
    emailWord: "Email Word Document to Yourself",
    ensure: "Please ensure that:",
    pdfPrint:
      "**We highly recommend printing in PDF or exporting Word to PDF for accurate formatting.**",
  },
  Alert: {
    download: "DOWNLOAD",
    ok: "OK",
    confirm: "CONFIRM",
    back: "BACK",
    cancel: "CANCEL",
    quit: "Are you sure to submit the answer? You can't go back when you submitted the answer",
    selectLang: "Please choose a language of your document.",
    permissionTitle: "Storage Permission Required",
    permissionText: "App needs access to your storage to download document",
    notGranted: "Storage Permission Not Granted",
    authFail: "Incorrect username or password",
    hello: "Hello! ",
    unknowError: "An error occurred, please try again later",
    exist: "User Already Exist",
    contactListAdded:
      "{{firstname}} {{surname}} has been added to contact list",
    // minimumCount: "Please submit at least {{minimumCount}} answers",
    minimumCount:
      "You have to add contact before proceeding.(At least {{minimumCount}} answers)",
    minimumCountContact:
      "You have to add contact before proceeding.(At least {{minimumCount}} contacts)",
    deleted: "{{firstname}} {{surname}}  was deleted.",
    unkonw: "an unknown error occurred ,please try again later",
    noApp: "No app associated with word document",
    emailOrphone: "Please fill in email or phone",
    notPaid: "unpaid document,please try later",
    documentDeleted: "{{name}} was deleted.",
    paidAlready: "Document is paid already",
    invalidPromoteCode: "Invalid Promo Code",
    expiredPromoteCode: "Expired Promo Code",
    reachedPromoteCode: "Promo Code Limit Reached",
    notApplicablePromoteCode: "Promo Code Not Applicable",
    redeemedPromoteCode: "Promo Code Already Redeemed",
    iamSmart: {
      infoSuccess: "Submission of personal information succeeded",
      infoFail: "Submission of personal information failed, please try again",
      loginFail: "Login failed, please try again",
      infoCancellation:
        "Submission of personal information cancelled, please try again",
      loginCancellation: "Login cancelled, please try again",
      loginTimeOut: "Login timed out, please try again",
    },
    emailDuplicated: "Email has been registered. Please use another email.",
    userNameDuplicated:
      "Username has been registered. Please use another username.",
    downloadWarning:
      "Please review the document carefully after downloading and printing to ensure that names, ID numbers and other information are correct. We are not responsible for any errors or omissions in the document.",
  },
  Checkout: {
    documentName: "Document Name: ",
    amount: "Amount: ",
    currency: "Currency: ",
    userProken: "Available Proken",
    noProken: " Your Proken balance is insufficient.",
    price: "Price",
    checkout: "CHECKOUT",
    downloaded: "Downloaded",
    title: "Payment Confirm",
    payLater: "PAY LATER",
    payment: "Payment",
    emailToYouSelf: "Email Document to Yourself",
    fps: "FPS",
    creditCard: "Credit Card",
    paid: "I had already paid",
    qrCode:
      "*Open the corresponding app to scan the QR code to settle payment.",
    unlimitedTitle: "Unlimited Documents",
    unlimitedDraft: "Unlimited drafting and downloads",
    unlimitedDoc:
      "Will, Advance Medical Directive & Enduring Power of Attorney",
    lifeTime: "Lifetime access, edit at anytime",
  },
  ForgetPassword: {
    title: "Forget Password",
    submit: "SUBMIT",
    noFound: "User Not Found",
    resetPassword: "Reset Password",
    otp: "Verification Code",
    newPassword: "New Password",
    confirmNewPassword: "Confirm New Password",
    resetSuccess: "Your password have been reset",
    remark:
      "If you did not register an email, we will need to reset your password manually and there will be a service charge of HK$100. Please email service@awesumcare.com if you still wish to do so.",
    invalidOTP: "Invalid Verification Code",
  },
  ContactList: {
    title: "Contact List",
    checkAnswer: "Submitted Answers",
    deleteMsg: "Are you sure you want to delete the answer?",
    duplicate: "Duplicate Contact List User",
    view: "View Contact",
    saved: "Number of people saved : ",
    min: "Minimum Count : ",
    max: "Maximum Count : ",
  },
  iamSmart: {
    backLining: "cancel link-up with iAM Smart",
    linking: "Link-up with iAM Smart",
    more: "More info",
    login: "Login with iAM Smart",
    openIAMSmart: "Open iAM Smart",
    supportLogin: "Supports login via “iAM Smart”",
    authorise: {
      pageTitle: "Provide Personal Information",
      title:
        "In order to complete the document answering,please authorise “iAM Smart” to provide the following personal information:",
      Authorise1: 'Welcome to log in to "Awesum Care" through “iAM Smart”',
      Authorise2:
        'This is the first time you have logged in to "Awesum Care" through “iAM Smart”. The system will request the following personal information via “iAM Smart” for linking up your existing account or creating a new account:',
      enName: "- English Name",
      chName: "- Chinese Name",
      birthDate: "- Date of birth",
      gender: "- Gender",
      idNo: "- HKID number",
      mobileNumber: "- Mobile Number",
      residentialAddress: "- Residential Address",
      button: "Personal Data from iAM Smart",
      cancel: "cancel log in to Awesum Care",
      step0: "Please follow the steps below:",
      step1: "1. Open “iAM Smart” mobile app in your mobile device",
      step2: "2. Tap on “To authorise”",
      step3: "3. Tap on “OK” to confirm the authorisation",
    },
    formFilling: {
      button: "Personal Data from iAM Smart",
      Authorise1:
        "In order to complete the account opening and linked-up with “iAM Smart”,please authorise “iAM Smart” to provide the following personal information:",
      mobile: "- Mobile Number",
      email: "- Email Address",
      address: "- Residential Address",
      title: "Authorise “iAM Smart” to provide personal information",
      pageTitle: "Provide Personal Information",
      step0: "Please follow the steps below:",
      step1: "1. Open “iAM Smart” mobile app in your mobile device",
      step2: "2. Tap on “To fill”",
      step3: "3. Tap on “OK” to confirm the authorisation",
      prefix: "- Prefix",
    },
    provideBy: "Data is provided by “iAM Smart”.",
    cancelLinking: "Unlink “iAM Smart”",
    successLinking:
      'You account have successfully link-up “iAM Smart”,You can now log in using "iAM Smart"',
    successUnLink: "You have successfully unlink “iAM Smart”",
    optional: "(optional)",
    exist:
      "This is the first time you have logged in to Awesum Care account through “iAM Smart”. Please sign in an existing Awesum Care account or sign up a new account to complete the account link-up.",
    loginExist: "Login existing account",
    signUpNewAccount: "Sign up a new account",
    loginToLinking:
      "Please sign in an existing Awesum Care account for linking up “iAM Smart”",
  },
  ForgetUsername: {
    title: "Forgot Username",
    text: "Username has been sent to your email account",
  },
};
