export const HOME = "/";
export const PROFILE = "/profile";
export const FAQ = "/faq";
export const DOCUMENT = "/document";
export const MYDOCUMENT = "/mydocument";
export const AUTH = "/auth";
export const CHECKOUT = "/checkout";
export const SIGNUP = "/auth/signUp";
export const FORGETPASSWORD = "/auth/forgetPassword";
export const FORGETUSERNAME = "/auth/forgetUsername";
export const RESETPASSWORD = "/auth/restPassword";
export const RECOMMENDTEMPLATE = "/document/recommendTemplate";
export const SECTION = "/document/section";
export const CHECKOUTPAYMENT = "/checkout/payment";
export const DOCUMENTREADY = "/checkout/documentReady";
export const INITIALRECOMMENDTEMPLATEPAGE =
  "/document/initialRecommendTemplatePage";
export const IAMSMART = "/iamsmart";
export const IAMSMART_CONSENT = "/iamsmart/consent";
