import UserModel from "./userModel";
import AppModel from "./appModel";
import TemplateModel from "./templateModel";
import StaticContentModel from "./staticContentModel";
import ContactListModel from "./contentListModel";
import DocumentModel from "./documentModel";
import InvoiceModel from "./invoiceModel";
import FormModel from "./formModel";
import PricingPlanModel from "./pricingPlanModel";

const Models = [
  // Add new Models here
  FormModel,
  TemplateModel,
  StaticContentModel,
  ContactListModel,
  DocumentModel,
  InvoiceModel,
  AppModel,
  UserModel,
  PricingPlanModel,
];

const BindModels = (ViewComponent) => {
  let bindedComponent = ViewComponent;
  Models.forEach((model) => {
    bindedComponent = model.withProvider(bindedComponent);
  });
  return bindedComponent;
};

export { BindModels };
export default Models;
