import styled from "styled-components";

export const FooterContainer = styled.div`
  // margin-top: 20px;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: 980px) {
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }
`;

export const LogoImg = styled.img`
  width: auto;
  height: 60px;
`;

export const StroeImageRow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  flex-wrap: wrap;
  gap: 10px;

  img {
    width: auto;
    height: 60px;
    margin: 0px 10px;
  }

  @media (max-width: 980px) {
    img {
      height: 40px;
    }
  }
`;
