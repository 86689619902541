import React, { useContext, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { FooterContainer, LogoImg, StroeImageRow } from "./Footer.styles";
import Logo from "../../../assets/images/Logo2.png";
import GooglePlayImage from "../../../assets/images/googlePlayImage.png";
import AppleStoreImage from "../../../assets/images/appleStoreImage.png";
import supportImg from "../../../assets/images/support.png";
import UserModel from "../../../models/userModel";

export default function Footer() {
  const { t } = useTranslation();
  const { token } = useContext(UserModel.Context);
  const { pathname } = useLocation();
  const isAnswering = useMemo(
    () =>
      pathname.includes("/document/recommendTemplate") ||
      pathname.includes("/document/section") ||
      pathname.includes("/document/initialRecommendTemplatePage"),
    [pathname]
  );
  return (
    <FooterContainer
      style={{
        backgroundColor: "#f6f8f4",
        width: "100%",
        display: isAnswering ? "none" : "flex",
      }}
    >
      {!token && process.env.REACT_APP_IAMSMART === "true" && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <p>{t("iamSmart.supportLogin")}</p>
          <img style={{ width: 150, height: "auto" }} src={supportImg} alt="" />
        </div>
      )}

      <LogoImg src={Logo} style={{ marginTop: 20 }} />
      <StroeImageRow>
        <button
          type="button"
          onClick={() =>
            window.open(
              "https://apps.apple.com/hk/app/%E5%AE%89%E5%BF%83%E4%B8%89%E5%AF%B6/id1580009611",
              "_blank"
            )
          }
        >
          <img src={AppleStoreImage} alt="" />
        </button>

        <button
          type="button"
          onClick={() =>
            window.open(
              "https://play.google.com/store/apps/details?id=com.AWEsumCareApp",
              "_blank"
            )
          }
        >
          <img src={GooglePlayImage} alt="" />
        </button>

        {/* <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        > */}
        {/* <p
            style={{
              fontSize: 14,
              color: "#327b58",
              marginTop: 0,
              marginBottom: 5,
            }}
          >
            {t("iamSmart.supportLogin")}
          </p> */}

        {/* </div> */}
      </StroeImageRow>

      <h5
        style={{
          color: "#5d5f5d",
        }}
      >
        &copy; 2022 Awesum Care. All rights reserved.
      </h5>
    </FooterContainer>
  );
}
