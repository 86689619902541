import React, { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
import moment from "moment";
import Header from "./Header";
import Footer from "./Footer";
import AppModel from "../../../models/appModel";
import { LayoutContainer, MaxWidth } from "./Header.styles";
import PricingPlanModel from "../../../models/pricingPlanModel";
import UserModel from "../../../models/userModel";
import ToastMessageHandler from "../../../base/ToastMessageHandler";

// import { useLocation } from 'react-router-dom';

const firebaseConfig = {
  apiKey: "AIzaSyA7-IJrakcJFdX8K118_qGMsz6vINcLJeU",
  authDomain: "awesum-care-acp.firebaseapp.com",
  projectId: "awesum-care-acp",
  storageBucket: "awesum-care-acp.appspot.com",
  messagingSenderId: "826717570980",
  appId: "1:826717570980:web:d8b66b619255f892272331",
  measurementId: "G-MZ26CW3TFW",
};

export default function Layout() {
  const { t, i18n } = useTranslation();
  const { headerHeight, slideMenuWidth } = useContext(AppModel.Context);
  const { expireAt, logout } = useContext(UserModel.Context);
  const { getList } = useContext(PricingPlanModel.Context);
  const theme = useTheme();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const location = useLocation();
  const app = initializeApp(firebaseConfig);
  const analytics = getAnalytics(app);

  const logCurrentPage = () => {
    logEvent(analytics, "screen_view", {
      firebase_screen: window.location.pathname,
    });
  };

  useEffect(() => {
    logCurrentPage(); // log the first page visit
  }, [location, analytics]);

  useEffect(() => {
    document.title = t("mainTitle");
  }, [i18n.language]);

  useEffect(() => {
    getList().catch(() => {});
  }, []);

  useEffect(() => {
    if (!expireAt) {
      return;
    }

    if (moment().isAfter(moment(expireAt))) {
      ToastMessageHandler(t("Alert.iamSmart.loginTimeOut"), "error");
      logout(navigate);
    }
  }, [pathname, expireAt]);

  return (
    <>
      <Header />
      <LayoutContainer
        theme={theme}
        headerHeight={headerHeight}
        slideMenuWidth={slideMenuWidth}
        style={{
          backgroundColor: "#f6f8f4",
        }}
      >
        <MaxWidth>
          {/* <div className="layer1" />
          <div className="layer2" /> */}
          <Outlet />
        </MaxWidth>
      </LayoutContainer>
      <Footer />
    </>
  );
}
