import React from "react";
import {
  HOME,
  FAQ,
  AUTH,
  PROFILE,
  DOCUMENT,
  CHECKOUT,
  MYDOCUMENT,
  IAMSMART,
} from "./path";

const Home = React.lazy(() => import("../../views/Home"));
const FAQPage = React.lazy(() => import("../../views/FAQ"));
const Auth = React.lazy(() => import("../../views/Auth"));
const Profile = React.lazy(() => import("../../views/Profile"));
const Document = React.lazy(() => import("../../views/Document"));
const Checkout = React.lazy(() => import("../../views/Checkout"));
const MyDocument = React.lazy(() => import("../../views/MyDocument"));
const IAMSmart = React.lazy(() => import("../../views/IAMSmart"));

const MainRoute = [
  {
    Component: Home,
    path: HOME,
  },
  {
    Component: FAQPage,
    path: FAQ,
  },
  {
    Component: Auth,
    path: `${AUTH}/*`,
  },
  {
    Component: MyDocument,
    path: MYDOCUMENT,
    isPrivate: true,
  },
  {
    Component: Profile,
    path: PROFILE,
    isPrivate: true,
  },
  {
    Component: Document,
    path: `${DOCUMENT}/*`,
  },
  {
    Component: Checkout,
    path: `${CHECKOUT}/*`,
  },
  {
    Component: IAMSmart,
    path: `${IAMSMART}/*`,
  },
];

export default MainRoute;
