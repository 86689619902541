import styled from "styled-components";
import { Link } from "react-router-dom";

const BasicButton = styled.button`
  width: fit-content;
  padding: 0.5rem 1.5rem;
  border-radius: 0.5rem;
  font-weight: bold;
  transition: 300ms;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  cursor: pointer;

  &:hover {
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px,
      rgb(209, 213, 219) 0px 0px 0px 1px inset;
    transition: 300ms;
  }

  &:disabled {
    opacity: 0.4;
    cursor: not-allowed;
    &:hover {
      box-shadow: none;
    }
  }
`;

const FAQLinkButton = styled.a`
  width: fit-content;
  text-decoration: underline;
  transition: 300ms;
  // display: flex;
  // justify-content: center;
  // align-items: center;
  font-size: 1rem;
  cursor: pointer;

  &:disabled {
    background-color: #dedede;
    &:hover {
      box-shadow: none;
    }
  }
`;

const SubmitButton = styled(BasicButton)`
  background-color: #46795b;
  color: #ffffff;
  border: 1px solid #46795b;
`;

const FAQSButton = styled(FAQLinkButton)`
  // background-color: #000;
  color: #46795b;
  // border: ${({ theme }) => `1px solid ${theme.text}`};
`;

const FAQButton = styled(BasicButton)`
  background-color: #fff;
  color: #46795b;
  border: 2px solid #46795b;

  &:disabled {
    opacity: 0.4;
    background-color: #fff;
  }
`;

const FAQPageButton = styled(BasicButton)`
  background-color: #fff;
  color: #46795b;
  border: 2px solid #46795b;

  &:hover {
    background-color: #46795b;
    color: #ffffff;
  }
`;

const PrimaryButton = styled(BasicButton)`
  background-color: #46795b;
  color: #fff;
`;

const TagButton = styled(BasicButton)`
  border-radius: 0px;
  background-color: transparent;
  border-bottom: 2px solid #dedede;
  color: #888;

  &:disabled {
    background-color: transparent;
    color: #46795b;
    border-bottom: 2px solid #46795b;
  }

  &:hover {
    box-shadow: none;
  }
`;

const SecondaryButton = styled(BasicButton)`
  background-color: ${({ theme }) => theme.secondary};
  color: ${({ theme }) => theme.text};
`;

const NavigateButton = styled(Link)`
  width: fit-content;
  padding: 0.5rem 1.5rem;
  border-radius: 0.5rem;
  font-weight: bold;
  transition: 300ms;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  background-color: ${({ theme }) => theme.primary};
  color: #fff;
  text-decoration: none;

  &:hover {
    box-shadow: 0 0 10px 5px #dedede;
    transition: 300ms;
  }

  &:disabled {
    background-color: #dedede;
    &:hover {
      box-shadow: none;
    }
  }
`;

const AddNewButton = styled(BasicButton)`
  padding: 0;
  border-radius: 0.25rem;
  height: fit-content;
  // aspect-ratio: 1;
  display: flex;
  background-color: ${({ theme }) => `${theme.primary}`};
  color: #f6f8f4;

  &:disabled {
    background-color: #dedede;
    &:hover {
      box-shadow: none;
    }
  }

  svg {
    height: 30px;
    width: 30px;
  }
`;

const ReverseButton = styled.button`
  padding: 0;
  border-radius: 0.25rem;
  height: fit-content;
  // aspect-ratio: 1;
  display: flex;
  background-color: ${({ theme }) => theme.secondary};
  color: ${({ theme }) => theme.primary};
  padding: 5px;
  transition: all 300ms;

  img {
    height: 20px;
    width: 20px;
  }
`;

const SortButton = styled.button`
  padding: 0;
  border-radius: 0.25rem;
  height: fit-content;
  //aspect-ratio: 1;
  // background-color: ${({ theme }) => theme.secondary};
  color: ${({ theme }) => theme.primary};

  & div {
    justify-content: center;
    align-items: center;
    display: flex;
  }

  span {
    margin-right: 10px;
    font-weight: bold;
  }

  img {
    height: 20px;
    width: 20px;
  }
`;

const LinkButton = styled.button`
  color: ${({ theme }) => theme.text};
  font-size: 1rem;
  border-bottom: 1px solid transparent;
  transition: all 300ms ease-in-out;

  &:hover {
    border-bottom: ${({ hoverColor }) => `1px solid ${hoverColor}`};
  }
`;

const IAMSMARTButton = styled.button`
  background-color: #2b7367;
  border: none;
  color: #ffffff;
  padding: 4.375px 8.75px 4.375px 8.75px;
  cursor: pointer;
  outline: none;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Noto Sans SC", Arial, Helvetica, sans-serif;

  font-size: 16.2px;
  padding-left: 8.75px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  img {
    height: 35px;
    width: 26px;
    margin-right: 10px;
  }
`;

export const CloseButton = styled.button`
  position: absolute;
  right: 10px;
  top: 10px;

  @media (max-width: 650px) {
    top: 10px;
    right: 10px;
  }

  svg {
    color: ${({ theme }) => theme.primary};
    height: 30px;
    width: 30px;
  }
`;

export {
  PrimaryButton,
  SecondaryButton,
  AddNewButton,
  LinkButton,
  ReverseButton,
  SortButton,
  NavigateButton,
  FAQButton,
  FAQPageButton,
  FAQSButton,
  SubmitButton,
  IAMSMARTButton,
  TagButton,
};
