import { useCallback, useEffect, useState } from "react";
import moment from "moment";
import { isEmpty } from "lodash";
import { APIRequestGenerator } from "../base/BaseActions";
import APIHelper from "../base/APIHelper";
import BaseModel from "../base/BaseModel";

const module = "auth";

const useUserHook = (savedModelInstance = {}) => {
  // External Hooks

  // States, Memos, Refs
  const [token, setToken] = useState(
    savedModelInstance?.rememberMe ? savedModelInstance?.token : null
  );
  const [userData, setUserData] = useState(
    savedModelInstance?.rememberMe ? savedModelInstance?.userData : null
  );
  const [rememberMe, setRememberMe] = useState(true);
  const [expireAt, setExpireAt] = useState(
    savedModelInstance?.expireAt ? savedModelInstance?.expireAt : null
  );

  function checkIsUnlimited(data) {
    const { plan = {} } = data || {};
    if (!isEmpty(plan)) {
      if (plan?.expireAt && moment().isAfter(plan?.expireAt, "date")) {
        return false;
      }

      if (plan?.validFrom && moment().isBefore(plan?.validFrom, "date")) {
        return false;
      }

      if (plan?.status !== "active") {
        return false;
      }

      if (plan?.duration?.unit !== "lifetime") {
        return false;
      }

      return true;
    }

    return false;
  }

  // API Calls
  async function login(username, password) {
    const data = {
      username,
      password,
    };
    const { url } = APIRequestGenerator(
      `${process.env.REACT_APP_HOST}/${module}/login`,
      null,
      null
    );
    try {
      const response = await APIHelper(
        "POST",
        url.toString(),
        data,
        true,
        null
      );

      if (response?.record?.token) {
        setToken(response.record.token);
      }
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async function getMe(userToken) {
    const { url, headers } = APIRequestGenerator(
      `${process.env.REACT_APP_HOST}/${module}/me?_populate=plan`,
      null,
      userToken || token
    );
    try {
      const response = await APIHelper(
        "GET",
        url.toString(),
        null,
        true,
        headers
      );

      if (response?.record) {
        const isUnlimited = checkIsUnlimited(response?.record);
        setUserData({ ...response?.record, isUnlimited });
      }
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async function loginWithToken(TOKEN, withGetMe = true) {
    setToken(TOKEN);
    setRememberMe(true);

    if (!withGetMe) {
      return Promise.resolve();
    }

    try {
      const response = await getMe(TOKEN);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async function getList(params) {
    const { url, headers } = APIRequestGenerator(
      `${process.env.REACT_APP_HOST}/${module}`,
      params,
      token
    );
    try {
      const response = await APIHelper(
        "GET",
        url.toString(),
        null,
        true,
        headers
      );

      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async function updateOne(id, data, inputToken) {
    const { url, headers } = APIRequestGenerator(
      `${process.env.REACT_APP_HOST}/user/${id}`,
      null,
      inputToken || token
    );
    try {
      const response = await APIHelper(
        "PUT",
        url.toString(),
        data,
        true,
        headers
      );
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async function createOne(data) {
    const { url, headers } = APIRequestGenerator(
      `${process.env.REACT_APP_HOST}/${module}`,
      null,
      token
    );
    try {
      const response = await APIHelper(
        "POST",
        url.toString(),
        data,
        true,
        headers
      );

      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async function getOne(id, params) {
    const { url, headers } = APIRequestGenerator(
      `${process.env.REACT_APP_HOST}/${module}/${id}`,
      params,
      token
    );
    try {
      const response = await APIHelper(
        "GET",
        url.toString(),
        null,
        true,
        headers
      );

      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async function register(data) {
    const { url, headers } = APIRequestGenerator(
      `${process.env.REACT_APP_HOST}/${module}/register`,
      null,
      token
    );
    try {
      const response = await APIHelper(
        "POST",
        url.toString(),
        data,
        true,
        headers
      );

      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async function forgetPassword(data) {
    const { url, headers } = APIRequestGenerator(
      `${process.env.REACT_APP_HOST}/${module}/forgetPassword`,
      null,
      token
    );
    try {
      const response = await APIHelper(
        "POST",
        url.toString(),
        data,
        true,
        headers
      );

      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async function forgetUsername(data) {
    const { url, headers } = APIRequestGenerator(
      `${process.env.REACT_APP_HOST}/${module}/forgetUsername`,
      null,
      token
    );
    try {
      const response = await APIHelper(
        "POST",
        url.toString(),
        data,
        true,
        headers
      );

      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async function fetchUserExist(data) {
    const { url, headers } = APIRequestGenerator(
      `${process.env.REACT_APP_HOST}/${module}/is-exist`,
      null,
      token
    );
    try {
      const response = await APIHelper(
        "POST",
        url.toString(),
        data,
        true,
        headers
      );

      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async function resetPassword(data) {
    const { url, headers } = APIRequestGenerator(
      `${process.env.REACT_APP_HOST}/${module}/resetPassword`,
      null,
      token
    );
    try {
      const response = await APIHelper(
        "POST",
        url.toString(),
        data,
        true,
        headers
      );

      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async function iamSmartLogin(code) {
    const { url, headers } = APIRequestGenerator(
      `${process.env.REACT_APP_HOST}/${module}/login/iamsmart`,
      { code },
      token
    );
    try {
      const response = await APIHelper(
        "GET",
        url.toString(),
        null,
        true,
        headers
      );

      if (response?.record) {
        setExpireAt(
          moment()
            .add(response?.record?.expiresIn, "milliseconds")
            .toISOString()
        );
      }

      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async function iamSmartRequestFormFilling(
    inputToken,
    eMEFields,
    profileFields
  ) {
    const { url, headers } = APIRequestGenerator(
      `${process.env.REACT_APP_HOST}/user/iamsmart/formFillingInfo`,
      null,
      inputToken
    );
    try {
      const response = await APIHelper(
        "POST",
        url.toString(),
        {
          source: "iOS_Chrome",
          eMEFields,
          profileFields,
        },
        true,
        headers
      );

      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async function iamSmartGetRequestLog(ref) {
    const { url, headers } = APIRequestGenerator(
      `${process.env.REACT_APP_HOST}/iamsmartrequestlog/${ref}`,
      null,
      null
    );
    try {
      const response = await APIHelper(
        "GET",
        url.toString(),
        null,
        true,
        headers
      );

      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async function iamSmartObtainFormFilling(code) {
    const { url, headers } = APIRequestGenerator(
      `${process.env.REACT_APP_HOST}/user/iamsmart/obtainAnonymousFormFillingInfo`,
      null,
      null
    );
    try {
      const response = await APIHelper(
        "POST",
        url.toString(),
        { code },
        true,
        headers
      );

      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async function requestIAMSmartAnonymousFormFilling(
    lang,
    eMEFields,
    profileFields
  ) {
    const { url, headers } = APIRequestGenerator(
      `${process.env.REACT_APP_HOST}/user/iamsmart/anonymousFormFillingInfo`,
      null,
      null
    );
    try {
      const response = await APIHelper(
        "POST",
        url.toString(),
        {
          lang,
          brokerPage: false,
          eMEFields,
          profileFields,
        },
        true,
        headers
      );

      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async function iamSmartLinkUp(data, inputToken) {
    const { url, headers } = APIRequestGenerator(
      `${process.env.REACT_APP_HOST}/auth/iamsmart/link`,
      null,
      inputToken
    );
    try {
      const response = await APIHelper(
        "POST",
        url.toString(),
        data,
        true,
        headers
      );

      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async function iamSmartUnLink() {
    const { url, headers } = APIRequestGenerator(
      `${process.env.REACT_APP_HOST}/auth/unlink`,
      null,
      token
    );
    try {
      const response = await APIHelper(
        "POST",
        url.toString(),
        { method: "iamsmart" },
        true,
        headers
      );

      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async function authLinkUp(data, inputToken) {
    const { url, headers } = APIRequestGenerator(
      `${process.env.REACT_APP_HOST}/auth/link`,
      null,
      inputToken
    );
    try {
      const response = await APIHelper(
        "POST",
        url.toString(),
        data,
        true,
        headers
      );

      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  // Functions and Callbacks

  const logout = (navigate) => {
    setToken(null);
    setUserData(null);
    setExpireAt(null);
    window.localStorage.removeItem("UserModel");
    navigate("/");
  };

  function handelChagneRememberMe(e) {
    setRememberMe(e);
  }

  // onSaveInstanceState / onRestoreInstanceState

  const onSaveInstanceState = useCallback(
    () => ({
      token,
      userData,
      rememberMe,
      expireAt,
    }),
    [token, userData, rememberMe, expireAt]
  );

  // useEffects

  return {
    // State
    token,
    userData,
    rememberMe,
    expireAt,

    // onSaveInstanceState / onRestoreInstanceState
    onSaveInstanceState,

    // Function
    logout,
    handelChagneRememberMe,

    // API Calls
    login,
    getMe,
    getOne,
    getList,
    updateOne,
    createOne,
    fetchUserExist,
    register,
    resetPassword,
    forgetPassword,
    loginWithToken,
    iamSmartLogin,
    iamSmartGetRequestLog,
    iamSmartRequestFormFilling,
    iamSmartObtainFormFilling,
    requestIAMSmartAnonymousFormFilling,
    iamSmartLinkUp,
    iamSmartUnLink,
    checkIsUnlimited,
    authLinkUp,
    forgetUsername,
  };
};

const UserModel = BaseModel(useUserHook, "UserModel");

export { useUserHook };
export default UserModel;
