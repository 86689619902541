import styled from "styled-components";
import { ToastContainer, Flip } from "react-toastify";
import React from "react";

const StyledToastContainer = styled(ToastContainer)`
  .Toastify__toast {
    font-family: "Poppins";
    font-size: 14px;
    font-weight: bold;
    color: #5a5a5a;
  }
`;

export default function StyledToast({
  position = "top-right",
  hideProgressBar = true,
}) {
  return (
    <StyledToastContainer
      position={position}
      hideProgressBar={hideProgressBar}
      autoClose={5000}
      transition={Flip}
    />
  );
}
