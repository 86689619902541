import React from "react";
import { useTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { Container, Header } from "./NotFound.style";
import NoFoundImg from "../../assets/images/noFound.png";
import Button from "../../components/common/CustomButton";

export default function NotFound() {
  const theme = useTheme();
  const navigate = useNavigate();

  return (
    <Container className="mainContainer">
      <img src={NoFoundImg} alt="" />
      <Button
        onClick={() => navigate(-1)}
        style={{ marginTop: 40, fontSize: "1.5rem" }}
      >
        Go Back
      </Button>
    </Container>
  );
}
