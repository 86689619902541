import React from "react";
import { useTheme } from "@mui/material/styles";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import UndoIcon from "@mui/icons-material/Undo";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CloseIcon from "@mui/icons-material/Close";
import {
  PrimaryButton,
  SecondaryButton,
  AddNewButton,
  LinkButton,
  ReverseButton,
  SortButton,
  NavigateButton,
  FAQButton,
  FAQPageButton,
  FAQSButton,
  SubmitButton,
  IAMSMARTButton,
  TagButton,
  CloseButton,
} from "./CustomButton_styles";
import iamSmartIcon from "../../../assets/images/iamSmartIcon.png";

function CustomButton({
  onClick = () => {},
  children,
  style,
  type = "button",
  variant = "primary",
  hoverColor = null,
  customactionbutton,
  disabled,
  to,
}) {
  const theme = useTheme();
  const props = {
    theme,
    onClick,
    children,
    style,
    type,
    variant,
    customactionbutton,
    disabled,
    to,
  };

  switch (variant) {
    case "close":
      return (
        <CloseButton {...props} type={type}>
          <CloseIcon />
        </CloseButton>
      );
    case "tag":
      return <TagButton {...props}>{children}</TagButton>;
    case "iamSmart":
      return (
        <IAMSMARTButton {...props}>
          <img src={iamSmartIcon} alt="" />
          {children}
        </IAMSMARTButton>
      );
    case "submit":
      return <SubmitButton {...props}>{children}</SubmitButton>;
    case "faqs":
      return <FAQSButton {...props}>{children}</FAQSButton>;
    case "faq":
      return <FAQButton {...props}>{children}</FAQButton>;
    case "faqPage":
      return <FAQPageButton {...props}>{children}</FAQPageButton>;
    case "navigate":
      return (
        <NavigateButton target="_blank" to={to} {...props}>
          {children}
        </NavigateButton>
      );
    case "sort":
      return (
        <SortButton {...props}>
          {typeof customactionbutton === "function" && customactionbutton()}
        </SortButton>
      );
    case "reverse":
      return (
        <ReverseButton {...props}>
          <FontAwesomeIcon
            icon="fa-solid fa-right-left"
            style={{ transform: "rotateZ(90deg)", width: 20, height: 20 }}
          />
        </ReverseButton>
      );
    case "link":
      return (
        <LinkButton {...props} hoverColor={hoverColor || theme.primary}>
          {children}
        </LinkButton>
      );
    case "add":
      return (
        <AddNewButton {...props}>
          <AddIcon />
        </AddNewButton>
      );
    case "remove":
      return (
        <AddNewButton {...props}>
          <RemoveIcon />
        </AddNewButton>
      );

    case "undo":
      return (
        <AddNewButton {...props}>
          <UndoIcon />
        </AddNewButton>
      );

    case "secondary":
      return <SecondaryButton {...props}>{children}</SecondaryButton>;
    case "primary":
    // fall through
    default:
      return <PrimaryButton {...props}>{children}</PrimaryButton>;
  }
}

export default CustomButton;
