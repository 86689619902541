export default {
  mainTitle: "安心三寶",
  answer: "答案",
  username: "帳戶名稱",
  logout: "登出",
  login: "登入",
  phone: "電話號碼",
  password: "密碼",
  confirmPassword: "確認密碼",
  name: "名稱",
  gender: "性別",
  f: "女",
  m: "男",
  dob: "出生日期",
  dateOfBirth: "出生日期",
  email: "電郵地址",
  download: "下載",
  salutation: "稱呼",
  firstName: "名字",
  firstname: "名字",
  surname: "姓氏",
  lastName: "姓氏",
  firstNameZh: "名稱 (中文)",
  lastNameZh: "姓氏 (中文)",
  hkid: "身份證號碼/護照號碼",
  country: "國家 / 地區",
  address: "地址",
  line1: "地址1",
  line2: "地址2",
  line3: "地址3",
  city: "城市",
  state: "州 / 省 / 區",
  zip: "郵遞編號",
  select: "請選擇",
  Will: "平安紙/遺囑",
  EPA: "持久授權書",
  AD: "預設醫療指示",
  notPaid: "未付款",
  noRecord: "沒有紀錄",
  logoutsuccess: "你已經成功登出",
  loading: "載入中...",
  downloaded: "已下載",
  other: "其他",
  cancel: "取消",
  pleaseLogin: "請先登入",
  promotionCode: "優惠碼",

  Category: {
    title: "文件",
  },
  SignIn: {
    title: "登入",
    rememberMe: "記住我",
    forgotPassword: "忘記密碼？",
    or: "或",
    facebook: "Facebook",
    google: "Google",
    signUp: "註冊",
    need: "需要一個帳號？",
    pleaseLogin: "請登入以創建文件",
    keep: "保持登入",
    alreadyAc: "已有帳戶: ",
    login: " 登入",
    other: "或使用其他方式登入/註冊",
  },
  SignUp: {
    title: "註冊",
    register: "註冊",
    already: "已擁有帳戶?",
    loginhere: "接此登入",
    info: "你的個人資料",
    ensure:
      "Please ensure that you provide your complete and correct details (in accordance with your identity documents) as they will be auto-filled into future documents. The information provided will be kept confidential. For details, please refer to our",
    privacyPolicy: "隱私政策",
    next: "下一步",
    submit: "傳送",
    mr: "先生",
    ms: "女士",
    mrs: "太太",
    dr: "博士",
    miss: "小姐",
    HongKong: "香港",
    passwordRequired:
      "密碼必需包含至少8個字母數字組成，一位大寫英文字母，一位細寫英文字母",
    regSucess: "註册成功",
    confirmPasswordError: "密碼不相符",
    tncText: "註冊即表示確定本人已經年滿18歲，並同意 ",
    tnc: "會員條款及細則",
    policy: "隱私政策",
    refund: "退款政策",
    picsText: "本人不同意根據收集",
    pics: "",
    picsLong: "收集個人資料聲明",
    picsText1: "將所提供的個人數據用於直接促銷目的",
    and: "和",
  },
  Home: {
    title: "主頁",
    recent: "最近文件",
    Categories: "分類",
    documents: "訂立新文件",
    clear: "清除歷史",
    template: "文件",
    detail: "詳細",
    noRecord: "沒有紀錄",
    adDescription:
      "一份預設醫療指示指明自己一旦因陷於病患或長期昏迷等情況而精神不健全的話，他希望或不希望接受的醫療。在此處下載你的AD模板!",
    epaDescripton:
      "授權書（EPA）是一份文件，可讓你在無法做到的情況下指定個人或組織來管理你的事務。在此處下載你的EPA!",
    willDescription:
      "遺囑規定了一個人死後如何分配財產。在這裡查看我們的遺囑模板!",
    languageRemark: `請用選擇的語言填寫表格，如選擇中文請用中文填寫資料。`,
    noZhNameRemarks:
      '<p style="color:red">*你未有填寫中文姓名及名稱，請填寫後再創立文件</p>',
    findLayer: "律師/醫生預約",
    consultant: "諮詢顧問（非法律）",
    otherService: "其他服務",
    consultantText:
      "與經驗豐富的顧問預約，獲取針對您的傳承規劃和預設照顧計劃的個人化諮詢，保障你和家人的未來。",
    findLayerText:
      "與經驗豐富的律師和醫生預約，以起草或見證您的遺囑、持久授權書和預設醫療指示。",
    consult: "諮詢",
    appoint: "預約",
  },
  AdvanceCarePlanning: {
    title: "安心三寶",
    categoryTitle: "你希望訂立哪一種類文件?",
    title1: "你希望訂立哪一種文件?",
    text1:
      "請注意，安心三寶 ACP是一個提供文件和資訊的在線平台。 安心三寶 ACP不是律師事務所，並不提供有關遺產規劃的法律或專業意見。",
    Will: "平安紙/遺囑",
    EPA: "持久授權書",
    AD: "預設醫療指示",
    willText:
      "遺囑(又稱為「平安紙」)是一份法律文 件，用以列明在你離世後，你遺下的資產希望如何被分配。",
    epaText:
      "持久授權書容許授權人委任另一人，即受權人，以便授權人日後變得精神上無行為能力時，受權人可處理他的財務事項，",
    adText:
      "預設照顧計劃是指患有嚴重疾病的病人和家屬與醫護人員透過溝通，考慮到疾病的預後、治療的利與弊、病人的價值觀和意願等因素，來訂立日後病危時的醫療及個人照顧計劃。 ",
  },
  Profile: {
    profile: "個人檔案",
    title: "個人檔案",
    edit: "編輯個人檔案",
    myDocument: "我的文件 ",
    submit: "儲存",
    newPassword: "新密碼",
    confirmNewPassword: "確認新密碼",
    success: "更改成功",
    notPaid: "付款",
    notFinished: "未完成",
    editContentList: "聯絡人名單",
    delete: "刪除",
    deleteMsg:
      "你是否確定刪除此文件? 請注意，文件一經刪除，系統所儲存的資料將會被移除，你將無法再次下載此文件。",
    changePassword: "密碼",
    editDraft: "修改草稿",
    unlink: "你是否確定取消連結「智方便」？",
    unlinkSuccess: "你已取消連結「智方便」",
  },
  FAQ: {
    title: "常見問題",
    Will: "平安紙/遺囑",
    EPA: "持久授權書",
    AD: "預設醫療指示",
    type: "文件類型",
    faqFor: "詞彙表及常見問題",
    willTitle: "平安紙/遺囑詞彙表及常見問題",
    epaTitle: "持久授權書詞彙表及常見問題",
    epaFullForm: "持久授權書",
    adTitle: "預設醫療指示詞彙表及常見問題",
    adFullForm: "預設醫療指示",
    next: "下一題",
    prev: "上一題",
    glossary: "詞彙表",
    create: "創建",
    submit: "傳送",
    minError: "最少選擇",
    minError1: "選項",
    requiredError: "請回答問題",
    otherError: "請填上其他項目",
    maxError: "最多可選擇",
    maxError1: "選項",
    loopNext: "加入聯絡人",
    saveAnswer: "儲存答案",
    endLoop: "完成",
    necessary: "必須回答",
    save: "儲存草稿",
    saveContentList: "儲存變更",
    addContentList: "加入聯絡人名單",
    maxLoopCount: "最多可回答 {{loopCount}} 個答案",
    provide: "請填寫姓氏及名字",
    start: "開始",
  },
  Setting: {
    title: "設定",
    language: "語言",
  },
  Validation: {
    required: "▲ 必填",
    formatError: "▲ 請輸入正確",
    inValidData: "日期無效",
  },
  BlurScreen: {
    thanks: "感謝你所提供的資料",
    text1: "我們已找到適合你所需的文件。",
    text2: "您已回答所有問題。",
    goto: "建立文件",
    create: "創立文件",
    backHome: "回到主頁",
    Will: "平安紙/遺囑",
    EPA: "持久授權書",
    AD: "預設醫療指示",
    type: "文件類型",
    noDocument: "很抱歉，找不到適合您的文件",
    continue: "繼續",
    reviewFAQ: "請先閱讀詞彙表及常見問題（你必須完整閱讀才能創建文件）",
    review: "閱讀詞彙表及常見問題",
    documentRemark1: `<p style="font-size:16px;margin-top:10px;margin-bottom:0px;font-weight:bold;text-align:left;">1. 如果選擇中文文件，請確保所有答案皆以中文填寫，反之亦然</p>`,
    documentRemark2: `<p style="font-size:16px;margin-top:10px;margin-bottom:0px;font-weight:bold;text-align:left;">2. 如你下載檔案後需要再作修改，請勿刪除重要條款，否則文件可能無效</p>`,
    documentRemark3: `<div style="font-size:16px;margin-top:10px;margin-bottom:0px;font-weight:bold;text-align:left;">3. 文件要列印見證，如需醫生或律師轉介，請參閱<a target='_blank' href='https://awesumcare.com/%E9%A0%90%E7%B4%84%E8%A6%8B%E8%AD%89%E6%9C%8D%E5%8B%99/'>awesumcare.com/預約見證服務/</a></div>`,
    documentRemark4: `<div style="font-size:16px;margin-top:10px;margin-bottom:0px;font-weight:bold;text-align:left;">4. 一旦按下「創立文件」的按鈕，你便不能再修改草稿。如需更改，請在付款後下載Word版本自行修改。</div>`,
    saveDraft: "儲存草稿",
  },
  Support: {
    buy: "Buy Prokens",
    monthly: "Monthly Subscription",
    noSupport: "No Support",
    your: "您的",
    isReady: "已經準備好!",
    Will: "平安紙/遺囑",
    EPA: "持久授權書",
    AD: "預設醫療指示",
    pdf: "下載 PDF",
    word: "下載 Word 檔案",
    emailWord: "電郵 Word 文件",
    ensure: "請確保：",
    pdfPrint:
      "**我們建議使用PDF格式打印或將Word檔案另存為PDF，以確保最準確的格式。**",
  },
  Alert: {
    download: "下載",
    ok: "確定",
    confirm: "確定",
    back: "返回",
    cancel: "取消",
    quit: "傳送答案後將無法回到這條問題，是否確定繼續？",
    selectLang: "請選擇語言",
    permissionTitle: "需要存儲權限",
    permissionText: "應用程式需要存儲權限下載文件",
    notGranted: "未授予存儲權限",
    authFail: "用戶名稱或密碼錯誤",
    hello: "您好! ",
    unknowError: "發生未知錯誤，請稍後再試",
    exist: "用戶名稱已被使用",
    contactListAdded: "{{surname}} {{firstname}} 已新增到聯絡人名單",
    // minimumCount: "最少回答 {{minimumCount}} 個答案",
    minimumCount:
      "你需要先按加入聯絡人，才可回答下一題(最少{{minimumCount}})個答案",
    minimumCountContact:
      "你需要先按加入聯絡人，才可回答下一題(最少{{minimumCount}})個聯絡人",
    deleted: "已刪除 {{surname}} {{firstname}}",
    unkonw: "發生未知錯誤，請稍後再試",
    noApp: "沒有相關應用程式開啟 word 檔案",
    emailOrphone: "請填上電郵地址或電話號碼其中一項",
    notPaid: "文件尚未付款，請稍後再試",
    documentDeleted: "已刪除 {{name}}",
    paidAlready: "文件已付款",
    invalidPromoteCode: "無效優惠碼",
    expiredPromoteCode: "優惠碼已過期",
    reachedPromoteCode: "優惠碼已達限制使用次數",
    notApplicablePromoteCode: "優惠碼不適用於當前商品",
    redeemedPromoteCode: "優惠碼已被使用",
    iamSmart: {
      infoSuccess: "提供個人資料成功",
      infoFail: "提供個人資料失敗，請重試",
      loginFail: "登入失敗，請重試",
      infoCancellation: "提供個人資料取消，請重試",
      loginCancellation: "登入已取消，請重試",
      loginTimeOut: "登入逾時，請重試",
    },
    emailDuplicated: "電郵已被註冊，請使用其他電郵",
    userNameDuplicated: "用戶名稱已被註冊，請使用其他用戶名稱",
    downloadWarning:
      "下載及列印文件時，請細閱姓名、身份證號碼及其他資料，以確保資料準確無誤。對任何資料錯漏，本公司概不負責。",
  },
  Checkout: {
    documentName: "文件名稱: ",
    currency: "付款貨幣: ",
    amount: "價錢: ",
    userProken: "Proken餘額",
    noProken: " Proken餘額不足",
    price: "價錢",
    checkout: "付款",
    downloaded: "已下載",
    title: "付款確認",
    payLater: "稍後付款",
    payment: "付款",
    emailToYouSelf: "電郵到你的電子郵箱",
    fps: "轉數快/FPS",
    creditCard: "信用卡",
    paid: "我已付款",
    qrCode: "*請開啟你支付銀行手機應用程式去掃描二維碼以進行付款",
    unlimitedTitle: "無限三寶帳戶",
    unlimitedDraft: "無限次文件製作及下載（包括重新再做）",
    unlimitedDoc: "包括平安紙、 持久授權書及預設醫療指示",
    lifeTime: "永久帳戶， 隨時修改內容",
  },
  ForgetPassword: {
    title: "忘記密碼",
    submit: "確認",
    noFound: "沒有此用戶",
    resetPassword: "重設密碼",
    otp: "驗證碼",
    newPassword: "新密碼",
    confirmNewPassword: "確認新密碼",
    resetSuccess: "你的密碼已被重置",
    remark:
      "如您未有登記你的電郵，我們將以人手重設您的帳戶密碼，並收取港幣 100 元的服務費。如需要協助，請發送電子郵件至 service@awesumcare.com 。 ",
    invalidOTP: "驗證碼錯誤",
  },
  ContactList: {
    title: "聯絡人名單",
    checkAnswer: "已傳送答案",
    deleteMsg: "你是否確定刪除此已回答答案?",
    duplicate: "已有相同姓名的聯絡人名單",
    view: "查看聯絡人",
    saved: "已儲存人數 : ",
    min: "最少回答數 : ",
    max: "最多回答數 : ",
  },
  iamSmart: {
    backLining: "取消連結「智方便」",
    linking: "連結「智方便」",
    more: "了解更多",
    login: "智方便登入",
    openIAMSmart: "開啟智方便",
    supportLogin: "支援以「智方便」登入",
    authorise: {
      pageTitle: "提供個人資料",
      title: "請授權「智方便」提供以下個人資料，以便完成文件：",
      Authorise1: "歡迎你透過「智方便」登入「安心三寶」",
      Authorise2:
        "這是你首次透過「智方便」登入「安心三寶」，系統將要求「智方便」提供以下個人資料，以便為您連結原有帳號/開立新帳號：",
      enName: "- 英文姓名",
      chName: "- 中文姓名",
      birthDate: "- 出生日期",
      gender: "- 性別",
      idNo: "- 身份證號碼",
      mobileNumber: "- 流動電話號碼",
      residentialAddress: "- 住宅地址",
      button: "智方便個人資料",
      cancel: "取消登入安心三寶",
      step0: "請按照以下步驟：",
      step1: "1. 開啟你手機上的「智方便」應用程式",
      step2: "2. 點擊「繼續授權」",
      step3: "3. 點擊「確定」以完成授權",
    },
    formFilling: {
      button: "智方便個人資料",
      Authorise1:
        "請授權「智方便」提供以下個人資料，以便完成開戶申請，並連結你的帳戶：",
      mobile: "- 流動電話號碼",
      email: "- 電郵",
      address: "- 住宅地址",
      title: "授權「智方便」提供「填表通」內的資料",
      pageTitle: "提供個人資料",
      step0: "請按照以下步驟：",
      step1: "1. 開啟你手機上的「智方便」應用程式",
      step2: "2. 點擊「繼續填寫」",
      step3: "3. 點擊「確定」以完成授權",
      prefix: "- 稱銜",
    },
    provideBy: "表示該項由「智方便」提供。",
    cancelLinking: "取消連結「智方便」",
    successLinking: "帳戶已綁定「智方便」，您現在可以使用「智方便」登錄",
    successUnLink: "你已成功取消連結「智方便」",
    optional: "(如有)",
    exist:
      "這是你首次透過「智方便」登入「安心三寶」，請選擇登入現有的「安心三寶」帳戶或註冊新帳戶以完成帳戶綁定。",
    loginExist: "登入現有帳戶",
    signUpNewAccount: "註冊新帳戶",
    loginToLinking: "請登入現有的「安心三寶」帳戶以便為您連結原有帳號",
  },
  ForgetUsername: {
    title: "忘記用戶名稱",
    text: "用戶名稱已發送到您的電子節箱",
  },
};
