import React from "react";
import styled, { keyframes } from "styled-components";
import { useTheme } from "@mui/system";
import { useTranslation } from "react-i18next";

const ldsRing = keyframes`
   0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;

  p {
    position: absolute;
    bottom: -80px;
    margin: auto;
    left: 0;
    right: 0;
    text-align: center;
    color: ${({ theme }) => theme.primary};
    font-size: 20px;
  }
`;

const Loader = styled.div`
  display: inline-block;
  position: relative;
  width: 100px;
  height: 100px;

  div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 84px;
    height: 84px;
    margin: 8px;
    border: ${({ theme }) => `8px solid ${theme.primary}`};
    border-radius: 50%;
    animation: ${ldsRing} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: ${({ theme }) =>
      `${theme.primary} transparent transparent transparent`};
  }

  .lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
  }
  .lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
  }
  .lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
  }
`;

function Spinnersclass() {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <Wrapper>
      <Loader theme={theme}>
        <div />
        <div />
        <div />
        <div />
        {/* <p>{t("loading")}</p> */}
      </Loader>
    </Wrapper>
  );
}

export default Spinnersclass;
