import { useMemo, useState } from "react";
import { isArray } from "lodash";
import { APIRequestGenerator } from "../base/BaseActions";
import APIHelper from "../base/APIHelper";
import BaseModel from "../base/BaseModel";

const module = "pricingplan";

const usePricingPlanModel = (savedModelInstance = {}) => {
  const [pricingPlans, setPricingPlans] = useState();

  const unlimitedPricingPlan = useMemo(() => {
    if (!isArray(pricingPlans)) {
      return {};
    }
    return pricingPlans.find((e) => e.duration.unit === "lifetime");
  }, [pricingPlans]);

  async function getList(token, params) {
    const { url, headers } = APIRequestGenerator(
      `${process.env.REACT_APP_HOST}/${module}`,
      params,
      token
    );
    try {
      const response = await APIHelper(
        "GET",
        url.toString(),
        null,
        true,
        headers
      );
      setPricingPlans(response?.records);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async function checkoutPricingPlan(token, id) {
    const { url, headers } = APIRequestGenerator(
      `${process.env.REACT_APP_HOST}/${module}/${id}/checkout`,
      null,
      token
    );
    try {
      const response = await APIHelper(
        "POST",
        url.toString(),
        null,
        true,
        headers
      );
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  return {
    getList,
    checkoutPricingPlan,
    unlimitedPricingPlan,
  };
};

const PricingPlanModel = BaseModel(usePricingPlanModel, "PricingPlanModel");

export { usePricingPlanModel };
export default PricingPlanModel;
