import React, { Suspense, useContext, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import moment from "moment-timezone";
import PrivateRoute from "./base/PrivateRoute";
import { BindModels } from "./models";
import SpinnersClass from "./components/common/spinner/spinner";
import AppModel from "./models/appModel";
import "./App.css";
import NotFound from "./views/NotFound";
import Layout from "./components/common/Layout";
import MainRoute from "./components/common/route";
import StyledToast from "./components/common/StyledToast/StyledToast";
import "@react-pdf-viewer/core/lib/styles/index.css";
import PricingPlanModel from "./models/pricingPlanModel";

function App() {
  const { getCountryList } = useContext(AppModel.Context);
  const { getList } = useContext(PricingPlanModel.Context);

  useEffect(() => {
    getCountryList(null, { _limit: 200, _sort: "-sorting name" });
    getList(null);
  }, []);

  const defaultTimeZone = "Asia/Hong_Kong";

  moment.tz.setDefault(defaultTimeZone);

  return (
    <Suspense fallback={<SpinnersClass />}>
      <Routes>
        <Route element={<Layout />}>
          {MainRoute.map(({ path, Component, isPrivate = false }) => (
            <Route
              key={path}
              path={path}
              element={
                isPrivate ? (
                  <PrivateRoute component={Component} />
                ) : (
                  <Component />
                )
              }
            />
          ))}
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
      <StyledToast position="top-center" hideProgressBar />
    </Suspense>
  );
}

export default BindModels(App);
