import styled from "styled-components";
import { Link } from "react-router-dom";

export const Container = styled.div`
  // background-color: ${({ theme }) => theme.primary};
  display: flex;
  justify-content: space-between;
  padding: 10px 0px;
  width: 100%;
  max-width: 1280px;
  margin: auto;

  @media (max-width: 980px) {
    flex-wrap: wrap;
  }

  svg {
    fill: #fff;
  }
`;

export const MaxWidth = styled.div`
  max-width: 1280px;
  display: flex;
  width: 100%;
  flex-direction: column;
  position: relative;
  height: 100%;

  @media (max-width: 1300px) {
    display: block;
  }
`;

export const LeftRow = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 980px) {
    width: 100%;
    justify-content: center;
  }
`;

export const MiddleRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;

  a {
    margin: 0px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    color: ${({ theme }) => theme.text};
    font-size: 16px;
  }

  @media (max-width: 980px) {
    width: 100%;
    margin-top: 10px;
    flex-direction: column;

    a {
      font-size: 14px;
    }
  }
`;

export const RightRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: 20px;
  width: 25%;

  @media (max-width: 980px) {
    width: 100%;
    margin-top: 10px;
    //justify-content: space-between;
  }
`;

export const MyAccount = styled.a`
  color: #fff;
  font-size: 10px;
  cursor: pointer;
`;

export const LogOut = styled.button`
  color: #fff;
  font-size: 16px;
  // text-decoration: underline;
  display: flex;
  align-items: center;
  height: 30px;
  padding: 0px 15px;
  color: rgb(255, 255, 255);
  background: rgb(50, 123, 88);
  border-radius: 5px;
  align-items: center;
  justify-content: center;

  @media (max-width: 980px) {
    font-size: 14px;
  }
`;

export const LogoImg = styled.img`
  width: auto;
  height: 60px;
  margin: 0px 10px;

  @media (max-width: 980px) {
    width: auto;
    height: 40px;
  }
`;

export const LayoutContainer = styled.div`
  display: flex;
  height: auto;
  position: relative;
  transition: all 300ms;
  background-color: ${({ theme }) => theme.primary};
  flex-direction: column;
  align-items: center;

  @media (max-width: 1300px) {
    display: block;
    height: auto;
  }

  @media (max-width: 980px) {
    overflow: hidden;
    display: block;
  }

  .copyRight {
    transition: all 300ms;
    font-size: 15px;
    color: #dbdbdb;
    text-align: center;
    margin-left: ${({ slideMenuWidth }) => `${slideMenuWidth}px`};
    // background-color: #fbfbfc;
    padding-bottom: 10px;
  }

  .layer1 {
    position: absolute;
    align-self: center;
    // background-color: rgba(255, 255, 255, 0.25);
    width: 100%;
    // height: ${({ headerHeight }) => `calc(100% -  ${headerHeight}px)`};
    top: ${({ headerHeight }) => `${headerHeight}px`};
    box-sizing: border-box;

    @media (max-width: 1300px) {
      width: 100%;
    }
  }

  .layer2 {
    position: absolute;
    align-self: center;
    // background-color: rgba(255, 255, 255, 0.55);
    width: 98%;
    // height: ${({ headerHeight }) => `calc(98% -  ${headerHeight}px)`};
    top: ${({ headerHeight }) => `${headerHeight}px`};
    margin-top: 10px;

    @media (max-width: 1300px) {
      width: 100%;
    }
  }

  .mainContainer {
    transition: all 300ms;
    // padding: 10px 20px 20px 40px;
    // overflow hidden
    // overflow: scroll;
    // display: block;
    // background-color: #fff;
    // z-index: 999;
    align-self: center;
    position: relative;
    // box-sizing: border-box;
    width: 96%;
    // height: ${({ headerHeight }) => `calc(96% -  ${headerHeight}px)`};
    margin-top: 20px;
    margin-bottom: 0px;

    .title {
      font-size: 35px;
      color: ${({ theme }) => theme.text};
      font-weight: 500;
      margin: 0px;
    }

    @media (max-width: 1300px) {
      padding: 10px 20px 20px 20px;
      height: 100%;
      width: auto;
    }

    @media (max-width: 980px) {
      padding: 0px 20px 20px 20px;
      margin-top: 10px;
    }
  }
`;

export const Name = styled.a`
  color: ${({ theme }) => theme.text};
  font-size: 16px;
`;

export const CustomerButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: transparent;

  > img {
    height: 16px;
    width: 16px;
  }
`;

export const MenuButton = styled.button`
  display: flex;
  align-items: center;
`;

export const ChangeLngAndLoginRow = styled.div`
  display: flex;
  width: 20%;
  justify-content: flex-end;
  margin-right: 20px;
  align-items: center;

  ${Name} {
    display: none;
  }

  @media (max-width: 980px) {
    font-size: 14px;
    margin-top: 15px;
    width: 100%;
    margin-right: 70px;

    ${Name} {
      display: flex;
    }
  }
`;

export const LinkRow = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;

  @media (max-width: 980px) {
    justify-content: center;
    margin-right: 0px;

    ${Name} {
      display: none;
    }
  }
`;

export const LangText = styled.div`
  color: white;
  font-size: 16px;

  @media (max-width: 980px) {
    font-size: 14px;
  }
`;
