import React, { useEffect, useState, useContext } from "react";
import { Navigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import UserModel from "../models/userModel";
import SpinnersClass from "../components/common/spinner/spinner";
import ToastMessageHandler from "./ToastMessageHandler";
import { AUTH } from "../components/common/path";

function PrivateRoute({ component: RouteComponent }) {
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthed, setIsAuthed] = useState(false);
  const { t } = useTranslation();

  const userModel = useContext(UserModel.Context);

  const getMe = async () => {
    const result = await userModel
      .getMe()
      .then((response) => {
        if (response) {
          if (response.success) {
            setIsAuthed(true);
            return true;
          }
        }
        return false;
      })
      .catch(() => {
        setIsAuthed(false);
        return false;
      });

    return result;
  };

  useEffect(() => {
    if (userModel.token) {
      setIsLoading(false);
      setIsAuthed(true);
      return;
    }
    async function checkAuth() {
      setIsAuthed(false);
      let isOK = false;
      isOK = await getMe();

      if (!isOK) {
        ToastMessageHandler(t("pleaseLogin"), "error");
      }

      setIsLoading(false);
    }

    checkAuth();
  }, [userModel.token]);

  if (isLoading === true) return <SpinnersClass />;

  if (!isAuthed && !isLoading) return <Navigate to={{ pathname: AUTH }} />;

  return <RouteComponent />;
}

export default PrivateRoute;
