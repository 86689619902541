function APIHelper(method, url, data, isJSON = true, inputHeaders = null) {
  // if (!headers) {
  //   headers = {
  //     Accept: "application/json, text/plain, /",
  //     "Content-Type": "application/json",
  //   };
  // }

  const headers = {
    Accept: "application/json, text/plain, /",
    "Content-Type": "application/json",
    "X-API-KEY": `${process.env.REACT_APP_API_KEY}`,
    ...(inputHeaders || {}),
  };

  let isFailed = false;

  // const urlWithToken = new URL(url);
  // if (!urlWithToken.searchParams.has('access_token')) {
  //   urlWithToken.searchParams.set(
  //     'access_token',
  //     process.env.REACT_APP_API_TOKEN,
  //   );
  //   url = urlWithToken.toString();
  // }

  const options = { method, headers };

  if (data && isJSON) options.body = JSON.stringify(data);

  if (!isJSON) options.body = data;

  return new Promise((resolve, reject) => {
    fetch(url, options)
      .then((res) => {
        if (res.status === 200 || res.status === 201 || res.status === 202) {
          return res.json();
        }
        isFailed = true;
        return res.json();
      })
      .then((response) => {
        if (isFailed) throw response;
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export default APIHelper;
